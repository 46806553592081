import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import moment from 'moment'
import style from '../reports.module.css'
import classNames from 'classnames/bind'
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Checkbox
} from '@material-ui/core'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import * as Yup from 'yup'
import { injectIntl } from 'react-intl'
import Multiselect from 'multiselect-react-dropdown'
import './mutilpleSelectCustome.css'
import { INIT_FILTER_REPORTS } from '..'
import SendMailDialog from './reports/reports-table/action-list/SendEmailDialog'
import { useSelector } from 'react-redux'

const cx = classNames.bind(style)
const START_DATETIME_FORMAT = 'yyyy-MM-DDT00:00:00'
const END_DATETIME_FORMAT = 'yyyy-MM-DDT23:59:59'
const DATETIME_FORMAT = 'yyyy-MM-DD HH:mm:ss'

const ReportsFilter = ({
  searchFilters,
  setSearchFilters,
  handleSubmit,
  setIsSubmited,
  intl,
  isLoading,
  clearTransactionReport,
  agents,
  theme,
  terminalIdData,
  isExporting,
  generateAlert,
  isRefunded,
  isReversed,
  setIsRefunded,
  setIsReversed
}) => {
  const customStyles = {
    chips: {
      backgroundColor: 'var(--primary-color)'
    },
    option: {
      backgroundColor: theme === 'Dark' ? 'var(--button-dark)' : 'var(--page-light)'
    },
    searchBox: {
      zIndex: 2,
      backgroundColor: theme === 'Dark' ? 'var(--page-dark)' : 'var(--page-light)'
    }
  }

  const [selectedFromDate, handleFromDateChange] = useState(new Date())
  const [selectedToDate, handleToDateChange] = useState(new Date())
  const [terminalOptions, setTerminalOptions] = useState()
  const [terminalIds, setTerminalIds] = useState('')
  const [isIncludeSubAgent, setIsIncludeSubAgent] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [disableQuarterItems, setDisableQuarterItems] = useState([1, 2, 3, 4])
  const agentReportData = useSelector(state => state.companyDetails?.agentReportData?.data)

  useEffect(() => {
    setDisableQuarterItems(disableQuarterItems.filter(item => item > moment().quarter()))
    if (agentReportData != null) {
      formik.setFieldValue('agentCode', agentReportData.agentCode)
    }
  }, [])

  const handleDisableQuarterItems = item => {
    return item.quarterNumber && disableQuarterItems.includes(item.quarterNumber)
  }

  const multiselectRef = React.createRef()

  const handleSelect = selectedList => {
    const selectedTerminalIds = selectedList.map(option => option.terminalId)
    const terminalIdsString = selectedTerminalIds.join(',')
    setTerminalIds(terminalIdsString)
  }

  useEffect(() => {
    setTerminalOptions(terminalIdData.data.response)
  }, [terminalIdData.data.response])

  const QUARTER_1 = 1
  const QUARTER_2 = 2
  const QUARTER_3 = 3
  const QUARTER_4 = 4

  const periods = [
    {
      key: 'today',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.TODAY' })
    },
    {
      key: 'yesterday',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.YESTERDAY' })
    },
    {
      key: 'thisWeek',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.THIS_WEEK' })
    },
    {
      key: 'lastWeek',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.LAST_WEEK' })
    },
    {
      key: 'thisMonth',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.THIS_MONTH' })
    },
    {
      key: 'lastMonth',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.LAST_MONTH' })
    },
    {
      key: 'q1',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.Q1' }),
      quarterNumber: QUARTER_1
    },
    {
      key: '2ndQuarter',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.Q2' }),
      quarterNumber: QUARTER_2
    },
    {
      key: '3rdQuarter',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.Q3' }),
      quarterNumber: QUARTER_3
    },
    {
      key: '4thQuarter',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.Q4' }),
      quarterNumber: QUARTER_4
    }
  ]

  const FilterSchema = Yup.object().shape({
    refId: Yup.string()
      .trim()
      .matches(
        /^[0-9]{1,50}$/,
        intl.formatMessage({
          id: 'INVALID.REF.ID'
        })
      ),
    phoneNo: Yup.string()
      .trim()
      .matches(/^(\+|\d)[0-9]{3,20}$/, intl.formatMessage({ id: 'INVALID.PHONE.NUMBER' })),
    start: Yup.date()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .typeError('Invalid Date Format'),
    end: Yup.date()
      .required('AUTH.VALIDATION.REQUIRED_FIELD')
      .typeError('Invalid Date Format')
      .max(
        moment.utc().format(DATETIME_FORMAT),
        intl.formatMessage({
          id: 'TRANSACTION.INVALID.END_DATE_GREATER_THAN_FUTURE'
        })
      )
      .test(
        'endGreaterThenStart',
        intl.formatMessage({
          id: 'TRANSACTION.INVALID.END_DATE_GREATER_THAN_START_DATE'
        }),
        function(end) {
          if (selectedFromDate && end) {
            return moment(selectedFromDate).isBefore(moment(end))
          }
          return true
        }
      )
  })

  const formik = useFormik({
    initialValues: searchFilters,
    validationSchema: FilterSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = convertRequestPayload(values)
      searchFilters = {
        ...searchFilters,
        ...payload,
        terminalIds,
        isRefunded,
        isReversed,
        includeSubAgent: isIncludeSubAgent
      }
      if (selectedToDate == null) {
        const end = moment().format(END_DATETIME_FORMAT)
        handleToDateChange(end)
      }
      handleSubmit(searchFilters)
      setIsSubmited(true)
    }
  })

  useEffect(() => {
    const payload = convertRequestPayload(formik.values)

    searchFilters = {
      ...searchFilters,
      ...payload,
      terminalIds,
      isRefunded,
      isReversed,
      includeSubAgent: isIncludeSubAgent
    }
    handleSubmit(searchFilters)
  }, [formik.values, isRefunded, isReversed, isIncludeSubAgent])

  useEffect(() => {
    if (!isLoading) {
      formik.setSubmitting(false)
    }
  }, [isLoading])

  const convertRequestPayload = payload => {
    const result = {
      start: moment(payload.start).format(DATETIME_FORMAT),
      agentCode: payload.agentCode,
      end: moment(payload.end).format(DATETIME_FORMAT),
      refId: payload.refId,
      exRefId: payload.exRefId,
      voucherSerial: payload.voucherSerial,
      phoneNo: payload.phoneNo,
      period: payload.period,
      salesReports: payload.salesReports
    }
    return result
  }

  useEffect(() => {
    formik.setFieldError('start', null)
    formik.setTouched({}, false)
    if (selectedFromDate) {
      formik.setFieldValue('start', moment(selectedFromDate).format(DATETIME_FORMAT))
    } else {
      formik.setFieldValue('start', '')
    }
  }, [selectedFromDate])

  useEffect(() => {
    if (selectedToDate) {
      if (
        formik.values.period === 'thisWeek' ||
        formik.values.period === 'thisMonth' ||
        formik.values.period === 'q1' ||
        formik.values.period === '2ndQuarter' ||
        formik.values.period === '3rdQuarter' ||
        formik.values.period === '4thQuarter'
      ) {
        formik.setFieldValue(
          'end',
          moment(selectedToDate)
            .utc()
            .format(DATETIME_FORMAT)
        )
      } else {
        formik.setFieldValue('end', moment(selectedToDate).format(DATETIME_FORMAT))
      }
    } else {
      formik.setFieldValue(
        'end',
        moment()
          .utc()
          .format(END_DATETIME_FORMAT)
      )
    }
  }, [selectedToDate])

  const handleChangeAgentCode = e => {
    const value = e.target.value
    formik.setFieldValue('agentCode', value)
  }

  const getFromDateByQuarter = quarterNo => {
    const start = moment()
      .quarter(quarterNo)
      .startOf('quarter')
      .format(START_DATETIME_FORMAT)
    return start
  }

  const getToDateByQuarter = quarterNo => {
    const currentDate = moment().utc()
    const end = getEndDate(
      currentDate,
      moment()
        .quarter(quarterNo)
        .endOf('quarter'),
      END_DATETIME_FORMAT
    )
    return end
  }

  const handleClearForm = () => {
    clearTransactionReport()
    formik.resetForm()
    setIsRefunded(false)
    setIsReversed(false)
    formik.setFieldValue('refId', '')
    formik.setFieldValue('voucherSerial', '')
    formik.setFieldValue('phoneNo', '')
    formik.setFieldValue('exRefId', '')
    formik.setFieldValue('salesReports', 'DETAILED_TRANSACTIONS_REPORT')
    formik.setFieldValue('agentCode', '')
    formik.setFieldValue('period', 'today')
    formik.setFieldValue('start', moment().format(START_DATETIME_FORMAT))
    formik.setFieldValue('end', moment().format(END_DATETIME_FORMAT))
    setSearchFilters(INIT_FILTER_REPORTS)
    multiselectRef.current.resetSelectedValues()
    setTerminalIds('')
  }

  useEffect(() => {
    const period = formik.values.period
    switch (period) {
      case 'today':
        {
          const start = moment().format(START_DATETIME_FORMAT)
          const end = moment()
            .utc()
            .format(DATETIME_FORMAT)
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case 'yesterday':
        {
          const start = moment()
            .subtract(1, 'day')
            .format(START_DATETIME_FORMAT)
          const end = moment()
            .utc()
            .subtract(1, 'day')
            .format(END_DATETIME_FORMAT)
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case 'thisWeek':
        {
          const start = moment()
            .startOf('isoweek')
            .format(START_DATETIME_FORMAT)
          const currentDate = moment().utc()

          const end = getEndDate(
            currentDate,
            moment()
              .endOf('isoWeek')
              .utc(),
            END_DATETIME_FORMAT
          )
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case 'lastWeek':
        {
          {
            const start = moment()
              .startOf('isoweek')
              .subtract(1, 'isoweek')
              .format(START_DATETIME_FORMAT)
            const end = moment()
              .utc()
              .endOf('isoweek')
              .subtract(1, 'isoweek')
              .format(END_DATETIME_FORMAT)
            handleFromDateChange(start)
            handleToDateChange(end)
          }
        }
        break
      case 'thisMonth':
        {
          const start = moment()
            .startOf('month')
            .format(START_DATETIME_FORMAT)

          const currentDate = moment().utc()
          const end = getEndDate(
            currentDate,
            moment()
              .endOf('month')
              .utc(),
            END_DATETIME_FORMAT
          )
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case 'lastMonth':
        {
          const start = moment()
            .startOf('month')
            .subtract(1, 'month')
            .format(START_DATETIME_FORMAT)
          const end = moment()
            .endOf('month')
            .subtract(1, 'month')
            .format(END_DATETIME_FORMAT)
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case 'q1':
        {
          const start = getFromDateByQuarter(QUARTER_1)
          const end = getToDateByQuarter(QUARTER_1)
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case '2ndQuarter':
        {
          const start = getFromDateByQuarter(QUARTER_2)
          const end = getToDateByQuarter(QUARTER_2)
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case '3rdQuarter':
        {
          const start = getFromDateByQuarter(QUARTER_3)
          const end = getToDateByQuarter(QUARTER_3)
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      case '4thQuarter':
        {
          const start = getFromDateByQuarter(QUARTER_4)
          const end = getToDateByQuarter(QUARTER_4)
          handleFromDateChange(start)
          handleToDateChange(end)
        }
        break
      default:
        break
    }
  }, [formik.values.period])

  const handleSubmitForm = () => {
    formik.submitForm()
  }

  const handleSendEmail = () => {
    setIsModalOpen(true)
  }

  const getEndDate = (currentDate, endDate, format) => {
    if (currentDate.isBefore(endDate)) {
      return currentDate.format()
    }
    return endDate.format(format)
  }

  return (
    <>
      {isModalOpen && (
        <SendMailDialog
          show={isModalOpen}
          onHide={() => {
            setIsModalOpen(false)
          }}
          filters={searchFilters}
          generateAlert={generateAlert}
        />
      )}
      <form className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}>
        <div className=" container row">
          <div className="col-md-6">
            <FormControl className={cx('width-100-percent')} variant="filled">
              <TextField
                label={intl.formatMessage({ id: 'TRANSACTION.LABEL.REF_ID' })}
                type="text"
                name="refId"
                onChange={formik.handleChange}
                value={formik.values.refId}
                onBlur={formik.handleBlur}
                margin="normal"
                variant="outlined"
                size="small"
                error={Boolean(formik.touched.refId && formik.errors.refId)}
                helperText={(formik.touched.refId && formik.errors.refId) || ''}
              />
            </FormControl>
          </div>
          <div className="col-md-6">
            {' '}
            <FormControl className={cx('width-100-percent')} variant="filled">
              <TextField
                label={intl.formatMessage({ id: 'TRANSACTION.LABEL.EXTERNAL_REF_ID' })}
                type="text"
                value={formik.values.exRefId}
                onChange={formik.handleChange}
                name="exRefId"
                margin="normal"
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
          <div className="col-md-6">
            {' '}
            <FormControl className={cx('width-100-percent')} variant="filled">
              <TextField
                label={intl.formatMessage({ id: 'TRANSACTION.LABEL.VOUCHER_SERIAL' })}
                type="text"
                value={formik.values.voucherSerial}
                onChange={formik.handleChange}
                name="voucherSerial"
                margin="normal"
                variant="outlined"
                size="small"
              />
            </FormControl>
          </div>
          <div className="col-md-6">
            {' '}
            <FormControl className={cx('width-100-percent')} variant="filled">
              <TextField
                label={intl.formatMessage({ id: 'TRANSACTION.LABEL.MOBILE_NUMBER' })}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.phoneNo}
                onBlur={formik.handleBlur}
                name="phoneNo"
                margin="normal"
                variant="outlined"
                size="small"
                error={Boolean(formik.touched.phoneNo && formik.errors.phoneNo)}
                helperText={(formik.touched.phoneNo && formik.errors.phoneNo) || ''}
              />
            </FormControl>
          </div>

          <div className="col-md-6">
            <FormControl size="small" variant="outlined" margin="normal" className={cx('width-100-percent')}>
              <InputLabel htmlFor="period">{intl.formatMessage({ id: 'TRANSACTION.LABEL.AGENT' })}</InputLabel>
              <Select
                value={formik.values.agentCode}
                onChange={e => handleChangeAgentCode(e)}
                input={<OutlinedInput labelWidth={40} name="agentCode" id="agentCode" />}
              >
                {formik.values.agentCode && (
                  <MenuItem value="">{intl.formatMessage({ id: 'TRANSACTION.LABEL.SELECT.ITEM.NONE' })}</MenuItem>
                )}
                {agents?.map(item => {
                  return (
                    <MenuItem key={item.id} value={item.code}>
                      {item.name} ({item.code})
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex col-md-6">
            <FormControlLabel
              control={
                <Checkbox checked={isIncludeSubAgent} onChange={event => setIsIncludeSubAgent(event.target.checked)} />
              }
              label={intl.formatMessage({ id: 'TRANSACTION.LABEL.INCLUDE_SUB_AGENT' })}
            />
          </div>

          <div className="col-md-6">
            <FormControl className={cx('width-100-percent')}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  onChange={e => {
                    formik.setFieldValue('period', '')
                    handleFromDateChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  value={selectedFromDate}
                  name="start"
                  autoOk={true}
                  label={intl.formatMessage({ id: 'TRANSACTION.LABEL.FROM' })}
                  ampm={false}
                  format="dd/MM/yyyy HH:mm:ss"
                  size="small"
                  margin="normal"
                  required={true}
                  maxDate={moment().format(DATETIME_FORMAT)}
                  error={
                    Boolean(formik.touched.start && formik.errors.start) ||
                    (formik.errors.start && formik.dirty == true)
                  }
                  helperText={formik.errors.start || ''}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </div>
          <div className="col-md-6">
            <FormControl className={cx('width-100-percent')}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  value={
                    formik.values.period === 'thisWeek' ||
                    formik.values.period === 'thisMonth' ||
                    formik.values.period === 'q1' ||
                    formik.values.period === '2ndQuarter' ||
                    formik.values.period === '3rdQuarter' ||
                    formik.values.period === '4thQuarter'
                      ? moment(selectedToDate)
                          .utc()
                          .format(DATETIME_FORMAT)
                      : selectedToDate
                  }
                  onChange={e => {
                    formik.setFieldValue('period', '')
                    handleToDateChange(e)
                  }}
                  autoOk={true}
                  name="end"
                  ampm={false}
                  label={intl.formatMessage({ id: 'TRANSACTION.LABEL.TO' })}
                  size="small"
                  margin="normal"
                  format="dd/MM/yyyy HH:mm:ss"
                  minDate={selectedFromDate}
                  maxDate={moment().format(END_DATETIME_FORMAT)}
                  error={
                    Boolean(formik.touched.end && formik.errors.end) || (formik.errors.end && formik.dirty == true)
                  }
                  helperText={formik.errors.end || ''}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </div>
          <div className="col-md-6">
            <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
              <InputLabel htmlFor="period"> {intl.formatMessage({ id: 'TRANSACTION.LABEL.PERIOD' })}</InputLabel>
              <Select
                value={formik.values.period}
                onChange={formik.handleChange}
                input={<OutlinedInput labelWidth={40} name="period" id="period" />}
              >
                {periods.map((item, index) => {
                  return (
                    <MenuItem disabled={handleDisableQuarterItems(item)} key={index} value={item.key}>
                      {item.value}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6">
            <FormControl
              variant="outlined"
              size="small"
              margin="normal"
              style={{ height: '50px' }}
              className={cx('width-100-percent')}
            >
              <Multiselect
                options={terminalOptions}
                onSelect={handleSelect}
                onRemove={handleSelect}
                displayValue="terminalId"
                placeholder={intl.formatMessage({ id: 'TRANSACTION.PLACEHOLDER.SELECT_TERMINAL_ID' })}
                style={customStyles}
                ref={multiselectRef}
              />
            </FormControl>
          </div>
          <div className="col-md-12">
            <FormControlLabel
              control={<Checkbox checked={isRefunded} onChange={event => setIsRefunded(event.target.checked)} />}
              label={intl.formatMessage({ id: 'TRANSACTION.LABEL.REFUND' })}
            />
            <FormControlLabel
              control={<Checkbox checked={isReversed} onChange={event => setIsReversed(event.target.checked)} />}
              label={intl.formatMessage({ id: 'TRANSACTION.LABEL.REVERSAL' })}
            />
          </div>
        </div>
        <div className={cx('filter-button')}>
          <button
            type="button"
            disabled={isLoading || isExporting}
            onClick={handleSubmitForm}
            className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
          >
            {intl.formatMessage({ id: 'TRANSACTION.BUTTON.RUN' })}
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <button
            type="button"
            disabled={isLoading || isExporting}
            onClick={handleSendEmail}
            className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom', 'send-mail-button', 'mr-5')}
          >
            {intl.formatMessage({ id: 'TRANSACTION.LABEL.SEND_EMAIL' })}
          </button>

          <button
            disabled={isLoading || isExporting}
            type="button"
            onClick={handleClearForm}
            className={cx(
              'button',
              'btn',
              'btn-secondary',
              'btn-lg',
              theme === 'Dark' ? 'btn-dark' : 'btn-secondary-custom'
            )}
          >
            {intl.formatMessage({ id: 'TRANSACTION.BUTTON.CLEAR' })}
          </button>
        </div>
      </form>
    </>
  )
}

export default injectIntl(ReportsFilter)
